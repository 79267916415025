import { createContext } from 'react';
import { Level, Role } from '../interfaces/candidates';

interface User {
  isAuthenticated: boolean;
  _id: string;
  firstName: string;
  lastName: string;
  email: string;
  employee: string;
  role: Role;
}
interface UsersContextType {
  user: User;
  levels: Level[];
  roles: Role[];
}

export const UsersContext = createContext<UsersContextType | null>(null);
