export enum ALL_SYSTEM_ROLES {
  LEAD = 'LEAD',
  SALES = 'SALES',
  REGULAR_EMPLOYEE = 'REGULAR_EMPLOYEE',
  C_LEVEL = 'C_LEVEL',
  ADMIN = 'ADMIN',
  FINANCES = 'FINANCES',
  HR = 'HR',
  RECRUITER = 'RECRUITER',
  SDM = 'SDM',
  MARKETING = 'MARKETING',
  INTERN = 'INTERN',
}

//In the objects below, each property should be an array.

export const ROLES_TO_PAGE = {
  homePage: Object.values(ALL_SYSTEM_ROLES),
  candidatesPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.RECRUITER,
  ],
  interviewsPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.RECRUITER,
  ],
  employeesPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.HR,
  ],
  timeTrackingPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.SALES,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.RECRUITER,
    ALL_SYSTEM_ROLES.SDM,
    ALL_SYSTEM_ROLES.MARKETING,
    ALL_SYSTEM_ROLES.REGULAR_EMPLOYEE,
    ALL_SYSTEM_ROLES.INTERN,
  ],
  timesheets: [
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.SDM,
  ],
  projectsPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.SALES,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.SDM,
  ],
  dealPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.SALES,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.SDM,
  ],
  contactPage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.SALES,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.SDM,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.MARKETING,
  ],
  financePage: [
    ALL_SYSTEM_ROLES.ADMIN,
    ALL_SYSTEM_ROLES.FINANCES,
    ALL_SYSTEM_ROLES.C_LEVEL,
    ALL_SYSTEM_ROLES.LEAD,
    ALL_SYSTEM_ROLES.SDM,
    ALL_SYSTEM_ROLES.HR,
    ALL_SYSTEM_ROLES.MARKETING,
  ],
  marketingPage: [ALL_SYSTEM_ROLES.MARKETING, ALL_SYSTEM_ROLES.ADMIN, ALL_SYSTEM_ROLES.C_LEVEL],
};

export const ROLES_FOR_HOMEPAGE: { allUser: ALL_SYSTEM_ROLES[]; intern: [ALL_SYSTEM_ROLES.REGULAR_EMPLOYEE] } = {
  allUser: Object.values(ALL_SYSTEM_ROLES),
  intern: [ALL_SYSTEM_ROLES.REGULAR_EMPLOYEE],
};
