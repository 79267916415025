import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AccessDenied } from './private-route.styles';
import MainLayout from '../../layout/main-layout/main-layout.component';
import { UsersContext } from '../../../contexts/user.context';
import isAccessAllowed from '../../../utils/isAccessAllowed';

interface PrivateRouteProps {
  children?: React.ReactNode;
  rolesForPage: string[];
  component?: React.ElementType;
  path: string;
  exact?: boolean;
}

const PrivateRoute = ({ children, rolesForPage, component, ...rest }: PrivateRouteProps) => {
  const context = useContext(UsersContext);
  const user = context?.user;
  const Component = component;

  return (
    <Route
      {...rest}
      render={() => {
        return user && user.isAuthenticated === true ? (
          isAccessAllowed(rolesForPage, user?.role) ? (
            <MainLayout userRoles={user.role}>
              {Component && <Component />}
              {!Component && children}
            </MainLayout>
          ) : (
            <MainLayout userRoles={user?.role}>
              <AccessDenied>Access denied</AccessDenied>
            </MainLayout>
          )
        ) : (
          user && <Redirect to="/login" />
        );
      }}
    />
  );
};

export default PrivateRoute;
