import { useHistory } from 'react-router-dom';

export const useMainLayoutData = () => {
  const history = useHistory();
  const goToThePage = (key) => history.push(key);

  return {
    goToThePage,
  };
};
