/* eslint-disable no-unused-vars */

import React from 'react';
import ReactDOM from 'react-dom';
import { ConfigProvider } from 'antd';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import LogRocket from 'logrocket';

import en_GB from 'antd/lib/locale/en_GB';
import reportWebVitals from './reportWebVitals';
import 'dayjs/locale/en-gb';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

import App from './App';
// import "antd/dist/antd.css";
import 'antd/dist/reset.css';

dayjs.locale('en-gb');
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const queryClient = new QueryClient();

const logRocketAppId = process.env.REACT_APP_LOGROCKET_APP_ID;
logRocketAppId ? LogRocket.init(logRocketAppId) : console.error('LogRocket App ID is not defined.');

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter>
      <ConfigProvider locale={en_GB}>
        <App />
      </ConfigProvider>
    </BrowserRouter>
  </QueryClientProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
