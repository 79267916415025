import { useMutation, useQuery } from 'react-query';

import { check, login } from '../api/auth';
import { getLevels, getRoles } from '../api/users';

export const useUsersData = () => {
  const { data: checkData } = useQuery('users', check, {
    retry: 2,
    refetchOnWindowFocus: false,
  });

  const { data: loginData, mutate: loginMutation, isLoading: isAuthChecking } = useMutation(login);
  const userData = loginData || checkData;

  const user = userData ? { isAuthenticated: true, ...userData } : null;

  const { data: roles = [] } = useQuery('roles', getRoles, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!user?.isAuthenticated,
  });

  const { data: levels = [] } = useQuery('levels', getLevels, {
    retry: 2,
    refetchOnWindowFocus: false,
    enabled: !!user?.isAuthenticated,
  });

  return {
    isAuthChecking,
    user,
    roles: Array.isArray(roles) ? roles : [],
    levels: Array.isArray(levels) ? levels : [],
    loginMutation,
  };
};
