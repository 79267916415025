import { Menu, Layout, Button, Row, Col } from 'antd';
import {
  // CalendarOutlined,
  AuditOutlined,
  HomeOutlined,
  HistoryOutlined,
  FileSearchOutlined,
  TeamOutlined,
  BookOutlined,
  PhoneOutlined,
  LogoutOutlined,
  DollarOutlined,
  NotificationOutlined,
} from '@ant-design/icons';

import { useMainLayoutData } from './main-layout.hooks';
import { ROLES_TO_PAGE } from '../../../constants/systemRoles';
import isAccessAllowed from '../../../utils/isAccessAllowed';

const MainLayout = ({ userRoles, children }) => {
  const { goToThePage } = useMainLayoutData();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout.Header style={{ background: 'white' }}>
        <Row>
          <Col flex="auto">
            <Menu
              onClick={({ key }) => (key !== 'logout' ? goToThePage(key) : null)}
              mode="horizontal"
              selectedKeys={[window.location.pathname]}
            >
              {isAccessAllowed(ROLES_TO_PAGE.homePage, userRoles) && (
                <Menu.Item data-test-id="home" key="/" icon={<HomeOutlined />}>
                  Home
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.candidatesPage, userRoles) && (
                <Menu.Item data-test-id="candidates" key="/candidates" icon={<AuditOutlined />}>
                  Candidates
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.timeTrackingPage, userRoles) && (
                <Menu.Item key="/timeTracking" icon={<HistoryOutlined />} data-test-id="timeTracking">
                  Time tracking
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.timesheets, userRoles) && (
                <Menu.Item data-test-id="timesheets" key="/timesheets" icon={<HistoryOutlined />}>
                  Timesheets
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.projectsPage, userRoles) && (
                <Menu.Item data-test-id="projects" key="/projects" icon={<FileSearchOutlined />}>
                  Projects
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.employeesPage, userRoles) && (
                <Menu.Item data-test-id="employees" key="/employees" icon={<TeamOutlined />}>
                  Employees
                </Menu.Item>
              )}
              {/* Hide because of unuse */}
              {/* {isAccessAllowed(ROLES_TO_PAGE.interviewsPage, userRoles) && (
                <Menu.Item data-test-id="interviews" key="/interviews" icon={<CalendarOutlined />}>
                  Interviews
                </Menu.Item>
              )} */}
              {isAccessAllowed(ROLES_TO_PAGE.dealPage, userRoles) && (
                <Menu.Item data-test-id="deals" key="/deals" icon={<BookOutlined />}>
                  Deals
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.contactPage, userRoles) && (
                <Menu.Item data-test-id="contacts" key="/contacts" icon={<PhoneOutlined />}>
                  Contacts
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.marketingPage, userRoles) && (
                <Menu.Item key="/leads" icon={<NotificationOutlined />}>
                  Leads
                </Menu.Item>
              )}
              {isAccessAllowed(ROLES_TO_PAGE.financePage, userRoles) && (
                <Menu.Item data-test-id="finance" key="/finance" icon={<DollarOutlined />}>
                  Finance
                </Menu.Item>
              )}
            </Menu>
          </Col>
          <Col flex="100px">
            <Button data-test-id="logout" danger type="link" href={`${process.env.REACT_APP_API_URL}/api/auth/logout`}>
              <LogoutOutlined />
              Logout
            </Button>
          </Col>
        </Row>
      </Layout.Header>
      <Layout.Content style={{ padding: '50px' }}>{children}</Layout.Content>
    </Layout>
  );
};

export default MainLayout;
