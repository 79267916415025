import axios from 'axios';
import { baseURL } from '../constants/url';

const path = `${baseURL}/api/users`;

export const getRoles = async () => {
  const res = await axios.get(`${path}/roles`);

  return res.data;
};

export const getLevels = async () => {
  const res = await axios.get(`${path}/levels`);

  return res.data;
};
